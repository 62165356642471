import React, { useState } from 'react';
import { Card, Image, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import moreOptionsIcon from '@assets/images/svg/more-options.svg';
import { history } from '@router/routes';
import { AreaDto } from '@models/dto/response/Area.dto';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@store/hooks';
import { updateArea } from '@store/reducers/area';
import CleaningZoneRenameModal from '@components/CleaningZoneRenameModal/CleaningZoneRenameModal';
import styles from './CleaningZoneSettingsItem.module.scss';

type Props = {
  zone: AreaDto;
  setShowModalDelete: () => void;
};

const CleaningZoneSettingsItem = (props: Props) => {
  const { zone, setShowModalDelete } = props;
  const dispatch = useAppDispatch();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showPopover, setShowPopover] = React.useState(false);
  const handleTogglePopover = () => {
    setShowPopover((prev) => !prev);
  };

  const editZone = () => {
    history.push('/cleaning/settings/surfaces', { zone });
  };

  const renameZone = (name: string) => {
    toast.promise(dispatch(updateArea({ zoneId: zone.id, name })).unwrap(), {
      pending: i18next.t('cleaning:toasts.pending.renameArea'),
      success: i18next.t('cleaning:toasts.success.renameArea'),
      error: i18next.t('cleaning:toasts.error.renameArea'),
    });
  };

  return (
    <>
      <Card
        style={{ backgroundColor: zone.color }}
        className={`h-100 mr-3 ${styles.cardContainer}`}
      >
        <Card.Body className="d-flex justify-content-center align-items-center text-wrap text-center">
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            show={showPopover}
            onToggle={handleTogglePopover}
            rootClose
            overlay={
              <Popover id={`zone-popover-${zone.id}`}>
                <Popover.Content>
                  <ListGroup>
                    <ListGroup.Item className="border-0" onClick={editZone}>
                      {i18next.t('shared:buttons.edit')}
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="border-0"
                      onClick={() => {
                        handleTogglePopover();
                        setShowEditModal(true);
                      }}
                    >
                      {i18next.t('shared:buttons.rename')}
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" onClick={setShowModalDelete}>
                      {i18next.t('shared:buttons.delete')}
                    </ListGroup.Item>
                  </ListGroup>
                </Popover.Content>
              </Popover>
            }
          >
            <div className={`${styles.moreOptions} pl-4 pt-1 pb-4`}>
              <Image src={moreOptionsIcon} width={6} height={27} />
            </div>
          </OverlayTrigger>
          <Card.Text className={styles.cardText}>{zone.name}</Card.Text>
        </Card.Body>
      </Card>

      <CleaningZoneRenameModal
        currentName={zone.name}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        onValidForm={renameZone}
      />
    </>
  );
};

export default CleaningZoneSettingsItem;
