import React, { Dispatch, SetStateAction, useRef } from 'react';
import i18next from 'i18next';
import { Form, Image } from 'react-bootstrap';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { FREQUENCY_JSON } from '@utils/constants';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { FrequencySurfaceEnum } from '@models/dto/request/FrequencySurfaceEnum.dto';
import { add, setHours } from 'date-fns';
import calendarIcon from '@assets/images/svg/calendar.svg';
import BaseWeekdayCheckboxes from '@components/shared/BaseWeekdayCheckboxes/BaseWeekdayCheckboxes';
import BaseDaySlotsCheckboxes from '@components/shared/BaseDaySlotsCheckboxes/BaseDaySlotsCheckboxes';
import BaseAmountInput from '@components/shared/BaseAmountInput/BaseAmountInput';
import styles from './BaseOccurenceForm.module.scss';

registerLocale('fr', fr);

type Props = {
  setFrequencyJson: Dispatch<SetStateAction<FrequencyJsonDto | undefined>>;
  frequencyJson?: FrequencyJsonDto;
  frequencyType: FrequencySurfaceEnum;
  setFrequencyType: Dispatch<SetStateAction<FrequencySurfaceEnum>>;
  occurences: FrequencySurfaceEnum[];
  labelFrequency?: string;
  labelWeekday?: string;
  labelSlots?: string;
};

const BaseOccurenceForm = (props: Props) => {
  const {
    frequencyJson,
    setFrequencyJson,
    setFrequencyType,
    occurences,
    frequencyType,
    labelFrequency,
    labelWeekday,
    labelSlots,
  } = props;

  // Change start date for frequency other than daily and none
  const changeStartDate = (date: Date) => {
    const newDate = setHours(date, 12);
    if (frequencyJson) {
      setFrequencyJson({ ...frequencyJson, startDate: newDate });
    } else {
      setFrequencyJson({ ...FREQUENCY_JSON, startDate: newDate });
    }
  };

  // Change the default value in terms of frequency selected
  const changeFrequency = (type: FrequencySurfaceEnum) => {
    setFrequencyType(type);
    if (type !== FrequencySurfaceEnum.NONE && type !== FrequencySurfaceEnum.DAILY) {
      setFrequencyJson({ ...FREQUENCY_JSON, [type]: true });
    } else {
      setFrequencyJson({ ...FREQUENCY_JSON });
    }
  };

  const calendarRef = useRef<DatePicker<any>>(null);
  const handleClickCalendarIcon = () => {
    if (calendarRef && calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  };

  return (
    <>
      {/* Occurence */}
      <Form.Group
        className="d-flex flex-column flex-lg-row align-items-lg-center mt-5 text-nowrap"
        controlId="frequency-type"
      >
        <Form.Label className={styles.inputLabel}>
          {labelFrequency || i18next.t('hardwareTemp:settings.modal.form.tempOccurence')}
        </Form.Label>
        <Form.Control
          as="select"
          custom
          className="baseInputLg ml-lg-3 mt-md-2"
          value={frequencyType}
          onChange={(e: any) => changeFrequency(e.target.value)}
        >
          {occurences.map((occurence, index: number) => (
            <option key={index} value={occurence}>
              {i18next.t(`shared:occurence.${occurence.toLowerCase()}`)}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      {frequencyType === 'dayAll' && (
        <>
          {/* Weekday */}
          <BaseWeekdayCheckboxes
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            label={labelWeekday}
          />
          {/* Day schedule */}
          <BaseDaySlotsCheckboxes
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            label={labelSlots}
          />
          {/* Amount */}
          {frequencyJson?.slotNoMatter ? (
            <BaseAmountInput frequencyJson={frequencyJson} setFrequencyJson={setFrequencyJson} />
          ) : null}
        </>
      )}
      {(frequencyType === 'monthly' ||
        frequencyType === 'quarterly' ||
        frequencyType === 'semiAnnual' ||
        frequencyType === 'annual') && (
        <>
          <Form.Text className="mt-5 mb-4">
            {i18next.t('shared:datepicker.frequencyHint')}
          </Form.Text>
          {/* Date picker */}
          <Form.Group
            className="d-flex flex-lg-row flex-column align-items-lg-center text-nowrap"
            controlId="date"
          >
            <Form.Label className={styles.inputLabel}>
              {i18next.t('shared:datepicker.frequencyLabel')}
            </Form.Label>
            <div className="d-flex flex-row">
              <DatePicker
                locale="fr"
                selected={
                  frequencyJson && frequencyJson.startDate
                    ? new Date(frequencyJson.startDate)
                    : null
                }
                onChange={(date: Date) => changeStartDate(date)}
                className={styles.datePicker}
                dateFormat="dd/MM/yyyy"
                placeholderText={i18next.t('shared:datepicker.placeholderStartDate')}
                minDate={add(new Date(), { days: 1 })}
              />
              <Image
                src={calendarIcon}
                className={styles.calendarIcon}
                onClick={handleClickCalendarIcon}
              />
            </div>
          </Form.Group>
        </>
      )}
    </>
  );
};
export default BaseOccurenceForm;
