import i18next from 'i18next';
import { FrequencyDto, FrequencySlotType } from '@models/dto/response/Frequency.dto';
import { FrequencySurfaceEnum } from '@models/dto/request/FrequencySurfaceEnum.dto';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { toast } from 'react-toastify';

export const getTitleFrequency = (type: FrequencySlotType): string => {
  switch (type) {
    case FrequencySlotType.MORNING:
      return i18next.t('shared:daySlots.slotMorning');
    case FrequencySlotType.MIDDAY:
      return i18next.t('shared:daySlots.slotMidday');
    case FrequencySlotType.AFTERNOON:
      return i18next.t('shared:daySlots.slotAfternoon');
    case FrequencySlotType.EVENING:
      return i18next.t('shared:daySlots.slotEvening');
    default:
      return '';
  }
};

export const getTitleFrequencySurface = (data: FrequencyJsonDto): string => {
  // if (data.dayAll) {
  //   return i18next.t('shared:occurence.daily');
  // }
  if (data.monthly) {
    return i18next.t('shared:occurence.monthly');
  }
  if (data.quarterly) {
    return i18next.t('shared:occurence.quarterly');
  }
  if (data.semiAnnual) {
    return i18next.t('shared:occurence.semiannual');
  }
  if (data.annual) {
    return i18next.t('shared:occurence.annual');
  }
  // eslint-disable-next-line no-case-declarations
  const countDay = [
    data.dayFriday,
    data.dayMonday,
    data.daySaturday,
    data.daySunday,
    data.dayThursday,
    data.dayTuesday,
    data.dayWednesday,
  ].reduce((a, b) => {
    return b ? a + 1 : a;
  }, 0);

  if (countDay !== 0) {
    return i18next.t('shared:occurence.daily');
  }

  return i18next.t('shared:occurence.none');
};

export const getTypeFrequencySurface = (data: FrequencyJsonDto): FrequencySurfaceEnum => {
  // if (data.dayAll) {
  //   return FrequencySurfaceEnum.DAILY;
  // }
  if (data.monthly) {
    return FrequencySurfaceEnum.MONTLHY;
  }
  if (data.quarterly) {
    return FrequencySurfaceEnum.QUARTERLY;
  }
  if (data.semiAnnual) {
    return FrequencySurfaceEnum.SEMIANNUAL;
  }
  if (data.annual) {
    return FrequencySurfaceEnum.ANNUAL;
  }
  // eslint-disable-next-line no-case-declarations
  const countDay = [
    data.dayFriday,
    data.dayMonday,
    data.daySaturday,
    data.daySunday,
    data.dayThursday,
    data.dayTuesday,
    data.dayWednesday,
  ].reduce((a, b) => {
    return b ? a + 1 : a;
  }, 0);

  if (countDay !== 0) {
    return FrequencySurfaceEnum.DAILY;
  }
  return FrequencySurfaceEnum.NONE;
};

export const displayNumberTime = (num: number): string => {
  return `0${num}`.slice(-2);
};

// mode 'form' for create/update form and mode 'data' to check existing frequencyJson and check its type
export const checkFormFrequencyValid = (
  frequencyJson: FrequencyJsonDto,
  mode = 'form',
  type?: FrequencySurfaceEnum,
): boolean => {
  switch (mode === 'form' && type ? type : getTypeFrequencySurface(frequencyJson)) {
    case FrequencySurfaceEnum.NONE: // NO FREQUENCIES
      if (Object.values(frequencyJson).includes(1) || Object.values(frequencyJson).includes(true)) {
        return false;
      }
      return true;
    case FrequencySurfaceEnum.DAILY: // DAILY
      // if no day is selected
      if (
        !frequencyJson.dayFriday &&
        !frequencyJson.dayMonday &&
        !frequencyJson.daySaturday &&
        !frequencyJson.daySunday &&
        !frequencyJson.dayThursday &&
        !frequencyJson.dayTuesday &&
        !frequencyJson.dayWednesday
      ) {
        toast.warn(i18next.t('cleaning:toasts.warn.noDaySelected'));
        return false;
      }
      // if no day is selected
      // // eslint-disable-next-line no-case-declarations
      // const countDay = [
      //   frequencyJson.dayFriday,
      //   frequencyJson.dayMonday,
      //   frequencyJson.daySaturday,
      //   frequencyJson.daySunday,
      //   frequencyJson.dayThursday,
      //   frequencyJson.dayTuesday,
      //   frequencyJson.dayWednesday,
      // ].reduce((a, b) => {
      //   return b ? a + 1 : a;
      // }, 0);

      // if (countDay !== 1) {
      //   toast.warn(i18next.t('cleaning:toasts.warn.dailyMoreThanOneDaySelected'));
      //   return false;
      // }

      // if no slot is selected
      if (
        !frequencyJson.slotMorning &&
        !frequencyJson.slotMidday &&
        !frequencyJson.slotAfternoon &&
        !frequencyJson.slotEvening &&
        !frequencyJson.slotNoMatter
      ) {
        toast.warn(i18next.t('cleaning:toasts.warn.noSlotsSelected'));
        return false;
      }
      if (frequencyJson.slotNoMatter && !frequencyJson.amount) {
        toast.warn(i18next.t('cleaning:toasts.warn.noAmount'));
        return false;
      }
      return true;
    case FrequencySurfaceEnum.MONTLHY:
    case FrequencySurfaceEnum.QUARTERLY:
    case FrequencySurfaceEnum.SEMIANNUAL:
    case FrequencySurfaceEnum.ANNUAL:
      // if no start date selected
      if (!frequencyJson.startDate) {
        toast.warn(i18next.t('cleaning:toasts.warn.noStartDate'));
        return false;
      }
      return true;

    default:
      return true;
  }
};

// Change all false to 0 and true to 1
export const formatFrequencyToNumber = (frequencyJson: FrequencyJsonDto) => {
  Object.keys(frequencyJson).forEach((key) => {
    if (frequencyJson[key] === true) {
      frequencyJson[key] = 1;
    } else if (frequencyJson[key] === false) {
      frequencyJson[key] = 0;
    }
  });
  return frequencyJson;
};

// Check if a slot is disable in settings
export const applyFrequenciesRestrictions = (slot: string, frequencies: FrequencyDto[]) => {
  if (
    (slot === 'slotMorning' &&
      frequencies.find((el) => el.type === FrequencySlotType.MORNING)?.active === false) ||
    (slot === 'slotMidday' &&
      frequencies.find((el) => el.type === FrequencySlotType.MIDDAY)?.active === false) ||
    (slot === 'slotAfternoon' &&
      frequencies.find((el) => el.type === FrequencySlotType.AFTERNOON)?.active === false) ||
    (slot === 'slotEvening' &&
      frequencies.find((el) => el.type === FrequencySlotType.EVENING)?.active === false)
  ) {
    return false;
  }
  return true;
};
