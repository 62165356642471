import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Form, Modal } from 'react-bootstrap';
import BaseOccurenceForm from '@components/shared/BaseOccurenceForm/BaseOccurenceForm';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { FREQUENCY_JSON } from '@utils/constants';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import BaseModalHeader from '@components/shared/BaseModalHeader/BaseModalHeader';
import BaseModalFooter from '@components/shared/BaseModalFooter/BaseModalFooter';
import { FrequencySurfaceEnum } from '@models/dto/request/FrequencySurfaceEnum.dto';
import { checkFormFrequencyValid, getTypeFrequencySurface } from '@utils/frequenciesUtils';
import styles from './CleaningSurfaceSettingsModal.module.scss';

const OCCURENCES = [
  FrequencySurfaceEnum.NONE,
  FrequencySurfaceEnum.DAILY,
  FrequencySurfaceEnum.MONTLHY,
  FrequencySurfaceEnum.QUARTERLY,
  FrequencySurfaceEnum.SEMIANNUAL,
  FrequencySurfaceEnum.ANNUAL,
];

type Props = {
  showModal: boolean;
  selectedItem?: SurfaceDto;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onValidFormCreate: (name: string, frequencyJson: FrequencyJsonDto) => void;
  onValidFormEdit: (id: number, name: string, frequencyJson: FrequencyJsonDto) => void;
};

const CleaningSurfaceSettingsModal = (props: Props) => {
  const { showModal, setShowModal, onValidFormCreate, onValidFormEdit, selectedItem } = props;
  const [name, setName] = useState<string>(selectedItem?.name ?? '');
  const [frequencyJson, setFrequencyJson] = useState<FrequencyJsonDto | undefined>(
    selectedItem ? JSON.parse(selectedItem.frequency.json) : FREQUENCY_JSON,
  );
  const [frequencyType, setFrequencyType] = useState<FrequencySurfaceEnum>(
    selectedItem
      ? getTypeFrequencySurface(JSON.parse(selectedItem.frequency.json))
      : FrequencySurfaceEnum.NONE,
  );

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.name !== name) {
        setName(selectedItem.name);
      }
      const newFrequencyJson = JSON.parse(selectedItem.frequency.json);
      if (!isEqual(frequencyJson, newFrequencyJson)) {
        setFrequencyJson(newFrequencyJson);
        setFrequencyType(getTypeFrequencySurface(JSON.parse(selectedItem.frequency.json)));
      }
    } else {
      setName('');
      setFrequencyJson(FREQUENCY_JSON);
    }
  }, [selectedItem]);

  const hideModal = () => {
    setShowModal(false);
    setName('');
    setFrequencyJson(FREQUENCY_JSON);
    setFrequencyType(FrequencySurfaceEnum.NONE);
  };

  const handleValidate = () => {
    if (frequencyJson) {
      if (!name) {
        toast.warn(i18next.t('cleaning:toasts.warn.noName'));
        return;
      }

      const isFrequencyFormValid = checkFormFrequencyValid(frequencyJson, 'form', frequencyType);
      if (isFrequencyFormValid) {
        if (selectedItem) {
          onValidFormEdit(selectedItem.id, name, frequencyJson);
          hideModal();
          return;
        }
        onValidFormCreate(name, frequencyJson);
        hideModal();
      }
    }
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={hideModal}
      aria-labelledby="cleaning-surface-settings-modal"
      centered
    >
      <BaseModalHeader
        id="cleaning-surface-settings-modal"
        title={i18next.t('cleaning:settings.surfaces.modal.title')}
      />
      <Modal.Body className="p-4">
        <Form>
          {/* Name */}
          <Form.Group
            className="d-flex flex-column flex-md-row align-items-md-center mt-5"
            controlId="name"
          >
            <Form.Label className={styles.inputLabel}>
              {i18next.t('cleaning:settings.surfaces.modal.form.name')}
            </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              value={name}
              className="baseInputLg ml-0 ml-md-3"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <BaseOccurenceForm
            labelFrequency={i18next.t('cleaning:settings.surfaces.modal.form.frequencies')}
            frequencyJson={frequencyJson}
            setFrequencyJson={setFrequencyJson}
            frequencyType={frequencyType}
            setFrequencyType={setFrequencyType}
            occurences={OCCURENCES}
          />
        </Form>
      </Modal.Body>
      <BaseModalFooter handleValidate={handleValidate} setShowModal={setShowModal} />
    </Modal>
  );
};

export default CleaningSurfaceSettingsModal;
