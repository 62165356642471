import Axios from './index';

export default {
  // AREA CRUD
  getAreas: (establishmentId: number) => {
    return Axios.get(`/establishment/${establishmentId}/area`);
  },

  createArea: (establishmentId: number, name: string) => {
    return Axios.post(`/establishment/${establishmentId}/area`, { name });
  },

  getArea: (id: number) => {
    return Axios.get(`/establishment/area/${id}`);
  },

  updateArea: (id: number, name: string) => {
    return Axios.put(`/establishment/area/${id}`, { name });
  },

  deleteArea: (id: number) => {
    return Axios.delete(`/establishment/area/${id}`);
  },

  // SURFACE CRUD
  getSurfaces: (areaId: number) => {
    return Axios.get(`/establishment/area/${areaId}/surface`);
  },

  createSurface: (areaId: number, name: string, frequency: string) => {
    return Axios.post(`/establishment/area/${areaId}/surface`, { name, frequencyJson: frequency });
  },

  getSurface: (id: number) => {
    return Axios.get(`/establishment/area/surface/${id}`);
  },

  updateSurface: (id: number, name: string, frequency: string) => {
    return Axios.put(`/establishment/area/surface/${id}`, { name, frequencyJson: frequency });
  },

  deleteSurface: (id: number) => {
    return Axios.delete(`/establishment/area/surface/${id}`);
  },
};
