import React, { useEffect, useState } from 'react';
import CleaningSurfaceSettingsModal from '@components/CleaningSurfaceSettingsModal/CleaningSurfaceSettingsModal';
import CleaningSurfaceSettingsTable from '@components/CleaningSurfaceSettingsTable/CleaningSurfaceSettingsTable';
import BaseNavbarWithHistory from '@components/shared/BaseNavbarWithHistory/BaseNavbarWithHistory';
import i18next from 'i18next';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AreaDto } from '@models/dto/response/Area.dto';
import { FrequencyJsonDto } from '@models/dto/FrequencyJson.dto';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { addSurface, deleteSurface, editSurface, getAreas, updateArea } from '@store/reducers/area';
import { SurfaceDto } from '@models/dto/response/Surface.dto';
import { toast } from 'react-toastify';
import plusWhite from '@assets/images/svg/plus-rond.svg';
import { formatFrequencyToNumber } from '@utils/frequenciesUtils';
import BaseNavbar from '@components/shared/BaseNavbar/BaseNavbar';
import editIcon from '@assets/images/svg/edit.svg';
import CleaningZoneRenameModal from '@components/CleaningZoneRenameModal/CleaningZoneRenameModal';
import styles from './CleaningSurfaceSettings.module.scss';

interface IZone {
  zone: AreaDto;
}

const CleaningSurfaceSettings = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SurfaceDto>();
  const location = useLocation<IZone>();
  const accountType = useAppSelector((state) => state.account.type);
  const areaDetail = useAppSelector((state) =>
    state.area.areas.find((area: AreaDto) => area.id === location.state.zone.id),
  );
  const surfaceList = areaDetail?.surfaces;
  const establishmentId = useAppSelector((state) => state.account.selectedEstablishment?.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!areaDetail && establishmentId) {
      dispatch(getAreas(establishmentId)).unwrap();
    }
  }, []);

  useEffect(() => {
    if (!showModal && selectedItem !== undefined) {
      setSelectedItem(undefined);
    }
  }, [showModal]);

  const addCleaningSurface = () => {
    setShowModal(true);
  };

  const createSurface = (name: string, frequencyJson: FrequencyJsonDto) => {
    if (
      frequencyJson.monthly ||
      frequencyJson.quarterly ||
      frequencyJson.semiAnnual ||
      frequencyJson.annual
    ) {
      frequencyJson.slotNoMatter = true;
    }
    setSelectedItem(undefined);
    toast.promise(
      dispatch(
        addSurface({
          areaId: location.state.zone.id,
          name,
          frequencyJson: JSON.stringify(formatFrequencyToNumber(frequencyJson)),
        }),
      ).unwrap(),
      {
        pending: i18next.t('cleaning:toasts.pending.createSurface'),
        success: i18next.t('cleaning:toasts.success.createSurface'),
        error: i18next.t('cleaning:toasts.error.createSurface'),
      },
    );
  };

  const updateSurface = (surfaceId: number, name: string, frequencyJson: FrequencyJsonDto) => {
    if (
      frequencyJson.monthly ||
      frequencyJson.quarterly ||
      frequencyJson.semiAnnual ||
      frequencyJson.annual
    ) {
      frequencyJson.slotNoMatter = true;
    }
    setSelectedItem(undefined);
    toast.promise(
      dispatch(
        editSurface({
          surfaceId,
          name,
          frequencyJson: JSON.stringify(formatFrequencyToNumber(frequencyJson)),
        }),
      ).unwrap(),
      {
        pending: i18next.t('cleaning:toasts.pending.updateSurface'),
        success: i18next.t('cleaning:toasts.success.updateSurface'),
        error: i18next.t('cleaning:toasts.error.updateSurface'),
      },
    );
  };

  const showEditModal = (data: SurfaceDto) => {
    setSelectedItem(data);
    setShowModal(true);
  };

  const removeSurface = (surfceId: number) => {
    toast.promise(dispatch(deleteSurface(surfceId)).unwrap(), {
      pending: i18next.t('cleaning:toasts.pending.deleteSurface'),
      success: i18next.t('cleaning:toasts.success.deleteSurface'),
      error: i18next.t('cleaning:toasts.error.deleteSurface'),
    });
  };

  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);

  const renameZone = (name: string) => {
    if (location.state.zone.id) {
      toast.promise(dispatch(updateArea({ zoneId: location.state.zone.id, name })).unwrap(), {
        pending: i18next.t('cleaning:toasts.pending.renameArea'),
        success: i18next.t('cleaning:toasts.success.renameArea'),
        error: i18next.t('cleaning:toasts.error.renameArea'),
      });
    }
  };

  return (
    <>
      <BaseNavbar accountType={accountType} parametersUrl="/settings" />
      <BaseNavbarWithHistory
        title={i18next.t('cleaning:settings.title')}
        showSettings={false}
        showHistory={false}
      />
      <Container fluid className="h-100 pt-5 cornerRound px-5">
        <Row>
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <Row className="d-flex justify-content-between align-items-center">
              <div className={styles.listTitle}>{areaDetail?.name ?? ''}</div>
              <div className={styles.circle}>
                <Image
                  src={editIcon}
                  width={22}
                  height={22}
                  className={styles.icon}
                  onClick={() => {
                    setShowRenameModal(true);
                  }}
                />
              </div>
            </Row>
            <Button
              className={`${styles.addButton} d-flex justify-content-center align-items-center btnXl`}
              onClick={addCleaningSurface}
            >
              <Image src={plusWhite} width={24} height={24} className="mr-2" />
              {i18next.t('cleaning:settings.surfaces.addZone')}
            </Button>
          </Col>
        </Row>
        {surfaceList && surfaceList.length > 0 ? (
          <CleaningSurfaceSettingsTable
            cleaningSurfaceList={surfaceList}
            setShowModalEdit={showEditModal}
            removeSurface={removeSurface}
          />
        ) : (
          <Row className="d-flex justify-content-center align-items-center h-100">
            <Col md={12} className="text-center tableHeader">
              {i18next.t('cleaning:settings.surfaces.empty')}
            </Col>
          </Row>
        )}
        <CleaningSurfaceSettingsModal
          selectedItem={selectedItem}
          showModal={showModal}
          setShowModal={setShowModal}
          onValidFormCreate={createSurface}
          onValidFormEdit={updateSurface}
        />

        <CleaningZoneRenameModal
          currentName={areaDetail?.name ?? location.state.zone.name}
          showModal={showRenameModal}
          setShowModal={setShowRenameModal}
          onValidForm={renameZone}
        />
      </Container>
    </>
  );
};

export default CleaningSurfaceSettings;
